import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SingleProduct from "../../shared-component/single-product-page"
import { productsData } from './index'

const data = {
    prodHeading:productsData[1].heading,
    prodDesc:'Accurately predict faults in machinery and prevent costly and untimely shutdowns.',
    prodImg:productsData[1]['prodImage'],
    subDesc:'Abnormally higher machine vibration can be an early indicator of machine failure. 4tellai – a condition-based monitoring can sense when machine vibration is over the normal range and instantly notifies you. This data allows you to take preventive measures resulting in uninterrupted machine performance.',
    para:[
      {
        content:[
          {
            head:'IO Gateways',
            desc:'Standardization has been one of our top priorities when designing our products. We have ensured that whatever be the machine’s manufacturer and technology, you can confidently use our products. IO Gateways can connect with Digital Inputs, Analog Inputs, Digital Outputs and Analog Outputs. In short, it connects to any machine with any type of input and output connection.'
          }
        ],
        img:'/static/svgs/io gateways.svg'
      },
      {
        content:[
          {
            head:'Low maintenance cost',
            desc:"We wanted to ensure that better vibration monitoring technology doesn’t lead to higher maintenance costs. The vibrational analysis kit works on the electricity from the machine on which it is installed. This feature has allowed us to eliminate the need for batteries, and therefore the cost of frequent battery replacements."
          },
          {
            head:'Plug & Play - 10 minutes only',
            desc:"Are you worried about interruptions in operations due to installations which may take hours or even days? Don’t worry. Ten minutes is all it takes to install the vibrational analysis kit! We designed the kit so that even your staff can install it themselves. However, if you do need us to install it for you, we are more than happy to do it."
          },
       ],
        img:'/static/svgs/maintenance.svg'
      },
      {
        content:[
          {
            head:'Integrated Machine Learning algorithm',
            desc:'Our IoT gateways filter unwanted and normal information (natural machine noise, for eg.), and supplies only essential data that are invaluable for critical decision making. Additionally, it saves cloud space, time and transfers cost by sending one whole data point instead of multiple raw data points.'
          },
       ],
        img:'/static/svgs/ML.svg'
      },
    ],
    tableData:[
      {
        heading:'Vibration Sensors',
        content:'Triaxial Vibration Sensor'
      },
      {
        heading:'Communication',
        content:'Wi-Fi'
      },
      {
        heading:'Vibration Range',
        content:'+50g'
      },
      {
        heading:'Linearity',
        content:'1%'
      },
      {
        heading:'Frequency Range',
        content:'2~15KHz'
      },
      {
        heading:'Make & Model',
        content:'Third Party Vibration Sensor'
      },
      {
        heading:'Gateway',
        content:'Integrated Gateway'
      },
    ]
  
  }




const TellAi = () => (
    <Layout>
        <SEO title="4 Tell Ai" />
         <SingleProduct data={data}/>
    </Layout>
)

export default TellAi